export class KTeam {
  acronym = '';
  id = 0;
  image_url = '';
  location = '';
  modified_at = '';
  name = '';
  slug = '';
  players: KPlayer[] = [];
}

export class KPlayer {
  age = 0;
  birthday = '';
  first_name = '';
  id = 0;
  image_url = '';
  last_name = '';
  modified_at = '';
  name = '';
  nationality = '';
  role = '';
  slug = '';
}