
import MobileMixin from "@/mixins/mobileMixin";
import {Component, Emit} from "vue-property-decorator";
import {KLeague} from "@/api/models/KLeague";
import {namespace} from "vuex-class";
import {KMatchDetails} from "@/api/models/KMatch";
import {LolService} from "@/api/services/lolService";
import KLiveMatches from "@/components/KLiveMatches.vue";
import {onClickLiveMatch} from "@/utils/globalUtils";


const lolModule = namespace('LolModule');

@Component({
  components: {KLiveMatches}
})
export default class KLolLeagues extends MobileMixin {

  @lolModule.Action
  public updateLeagueSelected!: (league: KLeague) => void;

  @lolModule.Getter
  public getLeagueSelected!: KLeague;

  @lolModule.Getter
  public getLeagues!: KLeague[];

  liveMatches: KMatchDetails[] = [];

  getImageFromLeague(league: KLeague) {
    try {
      return require('@/assets/lol/' + league.name + '.png');
    } catch (e) {
      return league.image_url;
    }

  }

  onClickLiveMatch(match: KMatchDetails): void {
    onClickLiveMatch(match);
  }

  onClickLeague(league: KLeague) {
    this.updateLeagueSelected(league);
    this.closeDrawer();
  }

  async retrieveLiveMatches() {
    this.liveMatches = await LolService.getLiveMatches();
  }

  mounted() {
    this.retrieveLiveMatches();
  }

  @Emit()
  closeDrawer(): void {
  }

}
