
import MobileMixin from "@/mixins/mobileMixin";
import {Component, Emit, VModel, Watch} from "vue-property-decorator";
import KLolImageBanner from "@/components/KImageBanner.vue";
import {KPlayer, KTeam} from "@/api/models/KTeam";
import { countries } from "@/static/enums/countries";
import {KSeries} from "@/api/models/KSeries";
import {KLeague, KLeagueSeries} from "@/api/models/KLeague";
import { LolService } from "@/api/services/lolService";
import {KMatchDetails} from "@/api/models/KMatch";
import {namespace} from "vuex-class";
import KLolSeriesMatches from "@/components/KcSeriesMatches.vue";
import dayjs from "dayjs";

const lolModule = namespace('LolModule');

@Component({
  components: {KLolSeriesMatches, KLolImageBanner}
})
export default class KLolTeam extends MobileMixin {

  @VModel({type: Object}) model!: { teamSelected: KTeam, seasonSelected: KLeagueSeries, matches: KMatchDetails[] } ;

  @lolModule.Getter
  public getLeagues!: KLeague[];

  players: KPlayer[] = [];

  KTeam = KTeam;

  roleOrder = ['top', 'jun', 'mid', 'adc', 'sup'];

  get groupMatchesByDay(): Map<string, KMatchDetails[]> {
    return this.model.matches
        .filter(match => match.first_team.acronym === this.model.teamSelected.acronym || match.second_team.acronym === this.model.teamSelected.acronym)
        .reduce((grouped, match) => {
      const date = dayjs(match.match.scheduled_at).toDate().toDateString();
      if (!grouped.has(date)) {
        grouped.set(date, []);
      }
      grouped.get(date).push(match);
      return grouped;
    }, new Map());
  }

  get getCountryNameFR(): string {
    if(this.model.teamSelected.location && countries[this.model.teamSelected.location]) {
      return countries[this.model.teamSelected.location];
    }
    return 'Inconnu';
  }

  async retrievePlayers(): Promise<void> {
    if (!this.model.teamSelected.id) {
      return;
    }
    this.players = await LolService.getPlayersBySeriesSlugAndAcronym(this.model.seasonSelected.slug, this.model.teamSelected.acronym);
  }

  getPlayersByRole(role: string): KPlayer[] {
    return this.players.filter(player => player.role === role);
  }


  @Watch('model.teamSelected')
  onSelectedTeamChanged() {
    this.retrievePlayers();
  }


}
