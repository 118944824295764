import {KLeague, KLeagueSeries} from "@/api/models/KLeague";
import {KMatch} from "@/api/models/KMatch";
import {KTeam} from "@/api/models/KTeam";

export class KSeries {
    begin_at = '';
    end_at = '';
    id = 0;
    league: KLeague = new KLeague();
    league_id = 0;
    live_supported = false;
    matches: KMatch[] = [];
    modified_at = '';
    name = '';
    prizepool = '';
    serie: KLeagueSeries = new KLeagueSeries();
    serie_id = 0;
    slug = '';
    teams: KTeam[] = [];
    tier = '';
    winner_id = 0;
    winner_type = '';

}
