
import MobileMixin from "@/mixins/mobileMixin";
import {Component, Emit, VModel, Watch} from "vue-property-decorator";
import {KLeague, KLeagueSeries} from "@/api/models/KLeague";
import {namespace} from "vuex-class";
import {KTeam} from "@/api/models/KTeam";
import {LolService} from "@/api/services/lolService";
import {KRanking} from "@/api/models/KRanking";
import {KSeries} from "@/api/models/KSeries";
import {KMatchDetails} from "@/api/models/KMatch";
import dayjs from "dayjs";
import KcSeriesMatches from "@/components/KcSeriesMatches.vue";
import KImageBanner from "@/components/KImageBanner.vue";
import KcRanking from "@/components/KcRanking.vue";

const lolModule = namespace('LolModule');

@Component({
  components: {KcSeriesMatches, KcRanking, KImageBanner}
})
export default class KLolRankingMatches extends MobileMixin {


  @lolModule.Getter
  public getLeagueSelected!: KLeague;

  @VModel({type: Object}) model!: { teamSelected: KTeam, seasonSelected: KLeagueSeries, matches: KMatchDetails[] };

  seriesSelected: KSeries = new KSeries();
  ranking: KRanking[] = [];
  series: KSeries[] = [];

  get getWinningTeam(): KTeam {
    const kTeam = this.seriesSelected.teams.find(item => item.id === this.seriesSelected.winner_id);
    if (kTeam) {
      return kTeam;
    }
    return new KTeam();
  }

  changeSeason(id: number) {
    const season = this.getLeagueSelected.series.find(item => item.id === id);
    if (season) {
      this.model.seasonSelected = season;
    }
  }

  changeSerie(id: number) {
    const serie = this.series.find(item => item.id === id);
    if (serie) {
      this.seriesSelected = serie;
    }
  }

  reset() {
    this.model.teamSelected = new KTeam();
    this.seriesSelected = new KSeries();
    this.ranking = [];
    this.series = [];
    this.model.matches = [];
  }

  async getSeriesBySlug(slug: string): Promise<void> {
    this.series = await LolService.getSeriesBySlug(slug);
  }

  async getRankingBySerie(slug: string): Promise<void> {
    this.ranking = await LolService.getLeagueRanking(slug);
  }

  async getMatchesBySeriesSlug(slug: string): Promise<void> {
    this.model.matches = await LolService.getMatchesBySeriesSlug(slug);
  }

  get groupMatchesByDay(): Map<string, KMatchDetails[]> {
    return this.model.matches.reduce((grouped, match) => {
      const date = dayjs(match.match.scheduled_at).toDate().toDateString();
      if (!grouped.has(date)) {
        grouped.set(date, []);
      }
      grouped.get(date).push(match);
      return grouped;
    }, new Map());
  }

  @Watch('seriesSelected')
  async onSeriesSelectedChanged(): Promise<void> {
    if (this.seriesSelected.id) {
      await this.getRankingBySerie(this.seriesSelected.slug);
      await this.getMatchesBySeriesSlug(this.seriesSelected.slug);
    }
  }

  @Watch('model.seasonSelected')
  async onSeasonSelectedChanged(): Promise<void> {
    if (this.model.seasonSelected.slug) {
      await this.getSeriesBySlug(this.model.seasonSelected.slug);
      if (this.series.length > 0) {
        this.seriesSelected = this.series[0];
      } else {
        this.reset();
      }
    }
  }

  @Watch('getLeagueSelected')
  @Watch('selectedTeam')
  onGetLeagueSelectedChanged(): void {
    this.model.seasonSelected = this.getLeagueSelected.series[0];
  }

  @Emit()
  seriesChanged() {
    return this.seriesSelected;
  }

}
