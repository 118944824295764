
import MobileMixin from "@/mixins/mobileMixin";
import {Component, Emit, Prop, VModel} from "vue-property-decorator";
import {KRanking} from "@/api/models/KRanking";
import {KTeam} from "@/api/models/KTeam";

@Component
export default class KcRanking extends MobileMixin {

  @VModel ({type: Array, required: true}) ranking!: KRanking[];

  @Emit()
  selectTeam(team: KTeam) {
    return team;
  }

}
