import {KMatchDetails} from "@/api/models/KMatch";

function getLiveStreamUrl(match: KMatchDetails) {
    const streams = match.match.streams_list;
    const frStream = streams.find(stream => stream.language === 'fr');
    if (frStream) {
        return frStream.raw_url;
    }
    const enStream = streams.find(stream => stream.language === 'en');
    if (enStream) {
        return enStream.raw_url;
    }
    const officialStream = streams.find(stream => stream.official);
    if (officialStream) {
        return officialStream.raw_url;
    }
    return '';
}

export function onClickLiveMatch(match: KMatchDetails): void {
    const link = getLiveStreamUrl(match);
    if (link) {
        window.open(link, '_blank');
    }
}
