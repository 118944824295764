
import MobileMixin from "@/mixins/mobileMixin";
import {Component, Watch} from "vue-property-decorator";
import KLolLeagues from "@/components/KLolLeagues.vue";
import {namespace} from 'vuex-class';
import {KTeam} from "@/api/models/KTeam";
import KLolRankingMatches from "@/components/KLolRankingMatches.vue";
import KLolTeam from "@/components/KLolTeam.vue";
import {KLeague, KLeagueSeries} from "@/api/models/KLeague";
import {KMatchDetails} from "@/api/models/KMatch";

const lolModule = namespace('LolModule');
@Component({
  components: {KLolTeam, KLolRankingMatches, KLolLeagues}
})
export default class LOL extends MobileMixin {

  @lolModule.Action
  public retrieveLeagues!: () => void;

  @lolModule.Getter
  public getLeagueSelected!: KLeague;

  smallMenu = false;

  model: { teamSelected: KTeam, seasonSelected: KLeagueSeries, matches: KMatchDetails[] } = {
    teamSelected: new KTeam(),
    seasonSelected: new KLeagueSeries(),
    matches: []
  };

  async mounted(): Promise<void> {
    await this.retrieveLeagues();
  }

  @Watch('getLeagueSelected', {immediate: true})
  onLeagueSelectedChanged() {
    this.model.teamSelected = new KTeam();
  }
}

