
import MobileMixin from "@/mixins/mobileMixin";
import {Component, VModel} from "vue-property-decorator";
import {KMatchDetails} from "@/api/models/KMatch";

@Component
export default class KcSeriesMatches extends MobileMixin {

  @VModel({type: Map, required: true}) groupMatchesByDay: Map<string, KMatchDetails[]>;
}
