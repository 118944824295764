
import MobileMixin from "@/mixins/mobileMixin";
import {Component, Emit, Prop} from "vue-property-decorator";
import {KStreamList} from "@/api/models/KMatch";

@Component
export default class KLiveMatches extends MobileMixin {

  @Prop({type: Array, required: true}) liveMatches!: KStreamList[];

  @Emit()
  public onClickLiveMatch(match: KStreamList) {
    return match;
  }

}
